@keyframes scale-as {

    to {
        width: 40px;
        background-color:red;
    }
}

* {
    font-family: 'Roboto','Montserrat';
}

h1.section-title-as {
    font-weight: 700;
    font-size: clamp(20px, 24px, 28px);
    text-align: center;
    line-height: 24px;
    font-style: normal;
    margin-bottom: 1rem;
}

h5.section-subtitle-as {
    font-weight: 400;
    font-size: clamp(16px, 19px, 22px);
    text-align: center;
    line-height: 27px;
}

div.gallery-slider-container-as {
    margin-top: 48px;
    overflow-x: scroll;
    cursor: grab;
    scroll-behavior: smooth;
    width: clamp(360px, 85vw,1200px);
}

div.gallery-slider-container-as::-webkit-scrollbar {
    display: none;
}

.gallery-slider-container-as > .gallery-slider-as {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-shrink: 0;
    width: fit-content;
    padding: 10px 0px;
}

.gallery-slider-as > .card-as {
    height: 296.01px;
    width: 267px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 1);
    margin: 0 24px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.gallery-slider-as > .card-as > .card-content-as > h3 {
    text-align: left;

}

.gallery-slider-as > .card-as > .card-content-as > p {
    text-align: left;
    padding: 1rem 1rem 0rem 1rem ;
}

.gallery-slider-as > .card-as > .card-image-as {


}

.gallery-slider-as > .card-as > .card-image-as > img {

}

.gallery-slider-as > .card-as:nth-child(even) {
    background-color:  rgba(255, 255, 255, 1);;
}

div.indicator-slider-container-as {
    display: flex;
    flex-direction: row;
    margin-top: 1.5rem;
}

div.indicator-slider-container-as > .indicator-as {
    width: 20px;
    height: 10px;
    background-color: #D9D9D9;
    border-radius: 10px;
    margin-right: 1rem;
}

div.indicator-slider-container-as > .indicator-as:hover {
    cursor: pointer;
}

div.indicator-slider-container-as > .indicator-as.selected-as {
    animation: scale-as 300ms ease forwards;
}

.card-as > .card-image-as {
    pointer-events: none;
}
