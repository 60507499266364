.Swiper-b {
    width: 100%;

}

.Swiper-slide-b {
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.box-b {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #eaeaea;
}

.small-container-b {
    display: flex;
    width: 100%;
}

.container-box-b {
    display: flex;
    position: relative;
}
.container-box-b .arrow-b {
    top: 50%;
    margin-top: 0px;
}
.container-box-b .arrow.arrow-left-b {
    left: 0;
    z-index: 100;
}
.container-box-b .arrow.arrow-right-b {
    right: 0;
    z-index: 100;
}



.pagination-bullet {
    width: 1.5rem;
    margin: 0 0.1rem;
}



/*
.Swiper{
    --add-bottom: 50px;
    padding-bottom: var(--add-bottom);
}
.Swiper [class^="swiper-button-"]{
    top: calc(50% - var(--add-bottom) / 2);
}*/

