.Swiper {
    width: 100%;

}

.Swiper-slide {
    /* Center slide text vertically */
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.box {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #eaeaea;
}

.small-container {
    max-width: 100%;
    margin: 0 auto;
}

.container-box {
    position: relative;
}
.container-box .arrow {
    position: absolute;
    top: 50%;
    margin-top: -5px;
}
.container-box .arrow.arrow-left {
    left: 0;
    z-index: 2;
}
.container-box .arrow.arrow-right {
    right: 0;
    z-index: 2;
}




/*
.Swiper{
    --add-bottom: 50px;
    padding-bottom: var(--add-bottom);
}
.Swiper [class^="swiper-button-"]{
    top: calc(50% - var(--add-bottom) / 2);
}*/

